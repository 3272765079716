import React from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import generalClasses from "./general.module.css"

import websiteImage from "../../images/case-studies/ondo-website.jpg"
import websiteImageTilted from "../../images/case-studies/ondo-website-2.jpg"
import Footer from "../../components/footer"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"
import LeftIcon from "../../images/arrow-left.inline.svg"
import RightIcon from "../../images/arrow-right.inline.svg"

const OndoStateWebsite = props => (
  <HomePageLayout pageTitle="Ondo State Website" background>
    <SEO title="Ondo State Website Case Study" />
    <main className={generalClasses.main}>
      <Link to={"/solutions/projects"} className={generalClasses.backLink}>
        <img src={arrowLeft} alt="" className={generalClasses.backLinkImage} />
        Projects
      </Link>
      <section
        className={[generalClasses.caseStudyIntro, generalClasses.section].join(
          " "
        )}
      >
        <div>
          <h1 className={generalClasses.caseStudyHeader}>ONDO STATE WEBSITE</h1>
          <p className={generalClasses.caseStudyDescription}>
            Prunedge designed and developed a website for Ondo State government.
          </p>
          <p className={generalClasses.caseStudyCategory}>
            UI / UX | WEB DEVELOPMENT
          </p>
          <a
            href="http://ondostate.gov.ng/"
            target="_blank"
            rel="noopener noreferrer"
            className={generalClasses.caseStudyLink}
          >
            VISIT WEBSITE
          </a>
        </div>
        <ul className={generalClasses.caseStudyDetails}>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>CLIENT</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Ondo State government
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>LOCATION</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Ondo, Nigeria
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              1 - 1000 Users
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
            <p className={generalClasses.caseStudyDetailContent}>Government</p>
          </li>
        </ul>
      </section>
      <section className={generalClasses.section}>
        <h1 className={generalClasses.meetCaseStudyHeader}>
          Meet the ondo state government
        </h1>
        <p className={generalClasses.meetCaseStudyText}>
          Ondo state is located in the tropical forest of South western part of
          Nigeria, bounded in the north by Ekiti and Kogi states, in the east by
          Edo state, in the west by Osun and Ogun states and in the south by the
          Atlantic ocean. Ondo state is richly blessed with copious natural
          resources waiting to be tapped. The strategic location of the state
          coupled with its peaceful and safe environment makes it very
          attractive to investors.
        </p>
      </section>
      <section
        className={[generalClasses.grid, generalClasses.section].join(" ")}
      >
        <div>
          <h1 className={generalClasses.sectionHeader}>our idea</h1>
          <p className={generalClasses.sectionText}>
            We decided to come up with a website that suited the Ondo State
            Government and also intuitive enough for users to understand how to
            navigate through the website easily.
          </p>
        </div>
        <div>
          <h1 className={generalClasses.sectionHeader}>Result</h1>
          <p className={generalClasses.sectionText}>
            An intuitively crafted website was delivered to the Ondo State
            Government which provides users with informations about the state
            and details about the MDAs in the state.
          </p>
        </div>
      </section>
      <img
        src={websiteImage}
        className={generalClasses.displayImage}
        alt=""
        style={{
          marginBottom: "105px",
        }}
      />
      <img
        src={websiteImageTilted}
        className={generalClasses.displayImageFull}
        alt=""
      />
      <div className={generalClasses.caseStudyPageLinks}>
        <Link
          to="/case-studies/odirs"
          className={generalClasses.caseStudyPageLink}
        >
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <LeftIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>
              PREVIOUS
            </span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              Ondo State Internal Revenue Service
            </span>
          </p>
        </Link>
        <Link
          to="/case-studies/develop-ondo"
          className={[
            generalClasses.caseStudyPageLink,
            generalClasses.caseStudyPageLinkRight,
          ].join(" ")}
        >
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>NEXT</span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              Develop Ondo
            </span>
          </p>
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <RightIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
        </Link>
      </div>
    </main>
    <Footer />
  </HomePageLayout>
)

export default OndoStateWebsite
